import React from "react"

/* Import Local Styles */
import "../icon.css"

const MailSvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 42 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H41.5973V31.1837H0V0ZM14.8766 15.6205L1.02992 1.77375V29.41L14.8766 15.6205ZM1.77375 1.02992L20.0834 19.3396C20.2551 19.5113 20.5412 19.6257 20.8273 19.6257C21.1134 19.6257 21.3422 19.5113 21.5711 19.3396L39.8808 1.02992H1.77375ZM26.0341 16.3643L22.2577 20.0262C21.8572 20.4268 21.3422 20.6556 20.7701 20.6556C20.1979 20.6556 19.6829 20.4268 19.2824 20.0262L15.5632 16.3071L1.77375 30.1538H39.8236L26.0341 16.3643ZM26.7779 15.6205L40.5674 29.41V1.77375L26.7779 15.6205Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default MailSvg
