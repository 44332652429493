import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

/* Import Global Context */

/* Import Global Components */
import PlusInCircleSvg from "~components/icon/inverted/plusInCircle"

const MenuLink = ({ to, title, handleClick }) => {
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(() => {
        setIsClicked(false)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [isClicked])

  return (
    <li>
      <Link
        to={to}
        onClick={() => {
          setIsClicked(true)
          handleClick()
        }}
      >
        {`${title} `}
        <span className={classnames("plus-icon", { clicked: isClicked })}>
          <PlusInCircleSvg />
        </span>
      </Link>
    </li>
  )
}

export default MenuLink
