import React, { useContext } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

/* Impport Global Context */
import FooterContext from "~context/footer"

/* Import Global Components */
import MailSvg from "~components/icon/normal/mail"
import PuppyMouthSvg from "~components/icon/normal/puppyMouth"

/* Import Local Styles */
import "./footer.css"

const Footer = () => {
  const [isOpen, setIsOpen] = useContext(FooterContext)

  const toggleFooter = () => {
    setIsOpen(!isOpen)
  }

  const closeFooter = () => {
    setIsOpen(false)
  }

  return (
    <div className={classnames("footer", { "is-open": isOpen })}>
      <div className="controls">
        <button href="test" className="mail" onClick={toggleFooter}>
          <MailSvg />
        </button>
      </div>
      <div className="contact-info">
        <div className="contacts">
          <Link to="/contact" onClick={closeFooter}>
            CONTACT
          </Link>
          <a href="mailto:hi@puppylove.com" target="_blank" rel="noreferrer">
            hi@puppylove.agency
          </a>
          <a href="tel:212-235-8800">212-235-8800</a>
        </div>
        <Link to="/signup" className="sign-up" onClick={closeFooter}>
          <PuppyMouthSvg />
          <p>Sign up</p>
        </Link>
      </div>
    </div>
  )
}

export default Footer
