import React from "react"

/* Import Local Styles */
import "../icon.css"

const HamburgerSvg = () => (
  <svg
    viewBox="0 0 47 37"
    className="icon no-fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.7778 16.4175C38.5683 16.8152 30.7608 16.9809 23.3889 16.9809C15.9835 16.9809 8.20958 16.7821 0 16.4175V21.5217C8.20958 21.0577 16.0171 20.8588 23.3889 20.8588C30.7943 20.8588 38.5683 21.0909 46.7778 21.5217V16.4175ZM46.7778 0.707031C38.5683 1.10476 30.7608 1.27049 23.3889 1.27049C15.9835 1.27049 8.20958 1.07162 0 0.707031V5.81127C8.20958 5.34725 16.0171 5.14838 23.3889 5.14838C30.7943 5.14838 38.5683 5.38039 46.7778 5.81127V0.707031ZM46.7778 31.8628C38.5683 32.2605 30.7608 32.4262 23.3889 32.4262C15.9835 32.4262 8.20958 32.2274 0 31.8628V36.967C8.20958 36.503 16.0171 36.3041 23.3889 36.3041C30.7943 36.3041 38.5683 36.5362 46.7778 36.967V31.8628Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default HamburgerSvg
