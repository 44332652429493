import React from "react"

/* Import Local Styles */
import "../icon.css"

const DoubleRightPointerSvg = ({ style }) => (
  <svg
    viewBox="0 0 31 27"
    className="icon no-fill"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M16.361 13.4696C13.8529 11.8236 11.7797 10.6368 10.1509 9.89178C8.522 9.14676 2.65436 6.6085 1.99726 6.43524C-1.10315 5.63824 0.331368 2.45025 0.331368 2.45025C0.331368 2.45025 2.19161 0.44043 3.32072 0.44043C3.6539 0.908233 4.03335 1.37604 4.44982 1.8525C4.8663 2.3203 5.26426 2.8141 5.64371 3.31655C7.40215 5.30905 9.35495 7.20625 11.5021 9.00816C13.6585 10.8101 15.7779 12.2914 17.8695 13.4696H16.361ZM17.8603 13.4696C15.7686 14.6824 13.6492 16.1898 11.4928 17.9917C9.33644 19.7936 7.38364 21.6908 5.63446 23.6833C5.255 24.1511 4.85704 24.6362 4.44057 25.1214C4.0241 25.6152 3.31146 26.5594 3.31146 26.5594C3.31146 26.5594 0.460937 24.4716 0.460937 23.4407C0.460937 22.4098 0.849645 20.9545 1.60855 20.452C3.91304 19.4471 8.522 17.8618 10.1601 17.1167C11.789 16.3717 13.8621 15.1589 16.3702 13.4783H17.8603V13.4696Z"
      className="fill-with-text-color"
    />
    <path
      d="M28.6612 13.4696C26.1532 11.8236 24.08 10.6368 22.4512 9.89178C20.8223 9.14676 14.9547 6.6085 14.2976 6.43524C11.1971 5.63824 12.6317 2.45025 12.6317 2.45025C12.6317 2.45025 14.4919 0.44043 15.621 0.44043C15.9542 0.908233 16.3336 1.37604 16.7501 1.8525C17.1666 2.3203 17.5646 2.8141 17.944 3.31655C19.7024 5.30905 21.6552 7.20625 23.8024 9.00816C25.9588 10.8101 28.0782 12.2914 30.1698 13.4696H28.6612ZM30.1605 13.4696C28.0689 14.6824 25.9495 16.1898 23.7931 17.9917C21.6367 19.7936 19.6839 21.6908 17.9348 23.6833C17.5553 24.1511 17.1573 24.6362 16.7409 25.1214C16.3244 25.6152 15.6118 26.5594 15.6118 26.5594C15.6118 26.5594 12.7612 24.4716 12.7612 23.4407C12.7612 22.4098 13.1499 20.9545 13.9088 20.452C16.2133 19.4471 20.8223 17.8618 22.4604 17.1167C24.0893 16.3717 26.1624 15.1589 28.6705 13.4783H30.1605V13.4696Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default DoubleRightPointerSvg
