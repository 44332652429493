import React, { useState } from "react"

// Import Global Context(s)
import FirstVisitContext from "~context/firstVisit"

const FirstVisitProvider = ({ children }) => {
  const [isFirstVisit, setIsFirstVisit] = useState(true)

  return (
    <FirstVisitContext.Provider value={[isFirstVisit, setIsFirstVisit]}>
      {children}
    </FirstVisitContext.Provider>
  )
}

export default FirstVisitProvider
