import React, { useContext } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import classnames from "classnames"

/* Import Global Contexts */
import MenuContext from "~context/menu"

/* Import Global Components */
import PuppyMouth from "~components/icon/inverted/puppyMouth"
import CrossSvg from "~components/icon/inverted/cross"

/* Import Local Components */
import LogoSvg from "./components/logo/logo"
import MenuLink from "./components/menuLink"

/* Import Local Styles */
import "./menu.css"

const Menu = () => {
  const { sanityContactSettings: contactInformation } = useStaticQuery(graphql`
    query HeaderComponentQuery {
      sanityContactSettings(_id: { eq: "contactSettings" }) {
        id
        _id
        generalQuestionsEmail
        generalQuestionsPhone
        newBusinessEmail
        newBusinessPhone
        socialLinks {
          title
          url
          _key
        }
      }
    }
  `)

  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <div className={classnames("menu", { open: isMenuOpen })}>
      <div className="header">
        <div className="logo-container">
          <div className="logo-wrapper left-align">
            <Link to="/" className="logo">
              <LogoSvg />
            </Link>
          </div>
        </div>
        <button className="hamburger" onClick={closeMenu}>
          <CrossSvg />
        </button>
      </div>
      <ul className="links">
        <MenuLink to="/works" title="Featured Work" handleClick={closeMenu} />
        <MenuLink to="/about" title="About" handleClick={closeMenu} />
        <MenuLink to="/ip" title="IP" handleClick={closeMenu} />
        <MenuLink to="/contact" title="Contact" handleClick={closeMenu} />
        <MenuLink to="/welcome" title="Welcome" handleClick={closeMenu} />
      </ul>
      <div className="contact">
        <div className="contacts">
          <div className="contact-business">
            <p>NEW BUSINESS</p>
            <a
              href={`mailto:${contactInformation.newBusinessEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactInformation.newBusinessEmail}
            </a>
            <a
              href={`tel:${contactInformation.newBusinessPhone}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactInformation.newBusinessPhone}
            </a>
          </div>
          <div className="contact-questions">
            <p>QUESTIONS AND LOVE</p>
            <a
              href={`mailto:${contactInformation.generalQuestionsEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactInformation.generalQuestionsEmail}
            </a>
            <a
              href={`tel:${contactInformation.generalQuestionsPhone}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactInformation.generalQuestionsPhone}
            </a>
          </div>
        </div>
        <div className="offsite-links">
          <ul>
            {contactInformation.socialLinks.map(link => (
              <li key={link._key}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Link to="/signup" className="mailing-list" onClick={closeMenu}>
          Get Updates{" "}
          <span>
            <PuppyMouth />
          </span>
        </Link>
      </div>
    </div>
  )
}

export default Menu
