import React from "react"

/* Import Local Styles */
import "../icon.css"

const PlusInCircleSvg = () => (
  <svg
    className="menu-icon no-fill plus-in-circle"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M26.6423 27.1525V29.9003C21.8853 29.9682 17.0731 31.4043 11.9187 31.6757V31.6644C11.8083 31.6757 11.6979 31.6757 11.5876 31.6757C9.68915 31.6757 8.16602 30.1152 8.16602 28.1815C8.16602 26.2479 9.68915 24.6874 11.5765 24.6874C11.6869 24.6874 11.7973 24.6987 11.9076 24.6987V24.6874C17.062 24.9588 21.8853 27.1525 26.6423 27.1525ZM30.7813 44.6572C30.5164 39.3764 29.1147 34.4348 29.0484 29.5724H26.3774C26.3774 34.4461 24.2362 39.3764 23.9713 44.6572H23.9824C23.9713 44.7703 23.9713 44.8833 23.9713 44.9964C23.9713 46.9301 25.4945 48.4906 27.3818 48.4906C29.2692 48.4906 30.7923 46.9301 30.7923 44.9964C30.7923 44.8833 30.7813 44.7703 30.7813 44.6572ZM43.9045 24.9927C43.7942 24.9927 43.6838 25.004 43.5734 25.004V24.9927C38.419 25.2641 33.5958 26.7002 28.8498 26.768V29.5159C33.6068 29.5159 38.419 31.7096 43.5734 31.981V31.9697C43.6838 31.981 43.7942 31.981 43.9045 31.981C45.7919 31.981 47.315 30.4205 47.315 28.4868C47.315 26.5532 45.7809 24.9927 43.9045 24.9927ZM31.5097 11.6607C31.5097 9.727 29.9866 8.1665 28.0992 8.1665C26.2119 8.1665 24.6887 9.727 24.6887 11.6607C24.6887 11.7737 24.6998 11.8868 24.6998 11.9999H24.6887C24.9536 17.2807 26.3554 22.2223 26.4216 27.0847H29.1036C29.1036 22.2109 31.2448 17.2807 31.5097 11.9999H31.4987C31.5097 11.8868 31.5097 11.7737 31.5097 11.6607Z"
        className="fill-with-text-color"
      />
    </g>
    <circle
      cx="27.5625"
      cy="27.5625"
      r="26.5417"
      className="stroke-with-text-color"
    />
    <defs>
      <clipPath>
        <rect
          width="39.149"
          height="40.3354"
          className="no-fill"
          transform="translate(8.16602 8.1665)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default PlusInCircleSvg
