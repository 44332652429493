import React, { useState, useEffect } from "react"

// Import Global Context(s)
import ColorContext from "~context/color"

const ColorProvider = ({ children }) => {
  const [color, setColor] = useState({
    text: "white",
    background: "black",
    newMenuColor: "black",
    newMenuBackgroundColor: "purple",
  })

  useEffect(() => {
    if (document) {
      // set text color
      document.documentElement.style.setProperty(
        "--text-color",
        `var(--color-${color.text})`
      )
      // set background color
      document.documentElement.style.setProperty(
        "--background-color",
        `var(--color-${color.background})`
      )
      // set inverted text color
      document.documentElement.style.setProperty(
        "--text-color-inverted",
        `var(--color-${color.newMenuColor})`
      )
      // set inverted background color
      document.documentElement.style.setProperty(
        "--background-color-inverted",
        `var(--color-${color.newMenuBackgroundColor})`
      )
    }
  }, [color])

  return (
    <ColorContext.Provider value={[color, setColor]}>
      {children}
    </ColorContext.Provider>
  )
}

export default ColorProvider
