import React, { useState } from "react"

// Import Global Context(s)
import FooterContext from "~context/footer"

const FooterProvider = ({ children }) => {
  const [isFooterOpen, setIsFooterOpen] = useState(false)
  return (
    <FooterContext.Provider value={[isFooterOpen, setIsFooterOpen]}>
      {children}
    </FooterContext.Provider>
  )
}

export default FooterProvider
