import React from "react"

/* Import Local Styles */
import "../icon.css"

const CrossSvg = () => (
  <svg
    className="menu-icon no-fill"
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.873 19.6983L19.3034 22.4827C14.369 18.0563 8.0985 14.9642 2.56685 10.3685L2.57742 10.3571C2.45383 10.2643 2.34082 10.16 2.2278 10.0557C0.283921 8.26176 0.183582 5.24118 1.99183 3.28176C3.80007 1.32233 6.81898 1.18034 8.75156 2.96381C8.86457 3.0681 8.96702 3.18386 9.08003 3.28815L9.09061 3.27669C14.1147 8.42235 17.002 15.2031 21.873 19.6983ZM9.74177 41.3473C14.4088 35.7458 17.5946 29.4138 22.0738 24.4241L19.3388 21.9001C14.7812 26.8388 7.97821 29.8114 2.76867 34.9122L2.77997 34.9227C2.66292 35.0268 2.55718 35.1414 2.45143 35.256C0.643187 37.2154 0.74353 40.236 2.67611 42.0195C4.60869 43.8029 7.62759 43.6609 9.43584 41.7015C9.54158 41.5869 9.63603 41.4619 9.74177 41.3473ZM41.5685 33.8216C41.4555 33.7173 41.3319 33.6245 41.2189 33.5202L41.2295 33.5087C35.6978 28.9131 29.416 25.8106 24.4929 21.3946L21.9233 24.179C26.7943 28.6742 29.6703 35.4445 34.6944 40.5902L34.705 40.5787C34.8074 40.6945 34.9204 40.7988 35.0335 40.9031C36.966 42.6865 39.9849 42.5445 41.7932 40.5851C43.6014 38.6257 43.4898 35.5947 41.5685 33.8216ZM41.3442 8.59942C43.1524 6.64 43.0521 3.61942 41.1195 1.83595C39.1869 0.0524768 36.168 0.194474 34.3597 2.1539C34.254 2.26848 34.1596 2.3935 34.0538 2.50809L34.0425 2.49766C29.3754 8.09914 26.1897 14.4311 21.7104 19.4209L24.4567 21.9553C29.0144 17.0166 35.8174 14.044 41.0269 8.94318L41.0156 8.93275C41.1327 8.82859 41.2384 8.71401 41.3442 8.59942Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default CrossSvg
