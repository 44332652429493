import React from "react"
import classnames from "classnames"
import "./logo.css"

const LogoSvg = ({ style = {}, className = "" }) => (
  <svg
    viewBox="0 0 1156 158"
    className={classnames("logo-svg no-fill", className)}
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M155.454 3.27393L154.157 69.3513C154.157 69.3513 150.004 113.116 159.693 133.275C166.008 146.456 174.4 152.745 189.279 152.745C206.061 152.745 219.902 145.078 231.148 129.743C242.394 114.408 247.931 95.7996 247.931 73.745V3.27393H251.478V73.745C251.478 95.7996 245.595 115.097 233.917 131.724C222.325 148.61 207.359 156.88 189.279 156.88C180.974 156.88 173.448 155.33 166.181 152.314C159.001 149.471 152.513 144.905 146.631 138.789C140.575 132.844 135.904 124.574 132.443 114.063C128.897 103.812 127.253 91.7505 127.253 77.9664V45.7461V3.27393H155.454Z"
      className="fill-with-text-color"
    />
    <path
      d="M490.325 16.0242C500.879 24.5531 506.069 35.2358 506.069 48.1583C506.069 58.4102 503.474 67.3699 498.11 75.0373C492.747 82.877 486.345 88.5629 478.906 92.0951C471.553 95.7996 463.94 97.5226 456.414 97.781H422.589L422.935 154.899H395.686C396.032 151.367 395.859 3.27393 395.859 3.27393H451.742C467.141 3.27393 479.857 7.40915 490.325 16.0242ZM483.404 84.9446C494.996 76.3295 510.741 51.0013 497.851 45.8323C459.009 30.1529 471.553 6.8061 440.323 6.8061H422.589V93.9042H454.597C464.373 93.6458 475.359 90.9751 483.404 84.9446Z"
      className="fill-with-text-color"
    />
    <path
      d="M363.072 16.0242C373.626 24.5531 378.73 35.2358 378.73 48.1583C378.73 58.4102 376.135 67.3699 370.772 75.0373C365.408 82.877 359.007 88.5629 351.567 92.0951C344.214 95.7996 336.601 97.5226 329.075 97.781H295.25L295.597 154.899H268.347C268.693 151.367 268.52 3.27393 268.52 3.27393H324.404C339.802 3.27393 352.519 7.40915 363.072 16.0242ZM356.065 84.9446C367.657 76.3295 383.402 51.0013 370.512 45.8323C331.757 30.1529 344.214 6.8061 313.071 6.8061H295.337V93.9042H327.345C337.034 93.6458 348.02 90.9751 356.065 84.9446Z"
      className="fill-with-text-color"
    />
    <path
      d="M94.6392 16.0242C105.193 24.5531 110.297 35.2358 110.297 48.1583C110.297 58.4102 107.702 67.3699 102.338 75.0373C96.9749 82.877 90.5734 88.5629 83.1337 92.0951C75.7806 95.7996 68.1679 97.5226 60.6418 97.781H26.9038L27.2499 154.899H0C0.34603 151.367 0.173015 3.27393 0.173015 3.27393H56.0569C71.3687 3.27393 84.0853 7.40915 94.6392 16.0242ZM87.6321 84.9446C99.2241 76.3295 114.968 51.0013 102.079 45.8323C63.3235 30.1529 75.7806 6.8061 44.6379 6.8061H26.9038V93.9042H58.9116C68.6005 93.6458 79.5869 90.9751 87.6321 84.9446Z"
      className="fill-with-text-color"
    />
    <path
      d="M568.181 86.6676L500.705 3.27393H532.367L549.149 23.7777L565.413 43.5924L592.576 77.2772L645.173 3.27393H648.72L593.614 84.6861V149.902L594.133 154.899H567.662L568.44 149.902L568.181 86.6676Z"
      className="fill-with-text-color"
    />
    <path
      d="M727.01 3.53238V152.487C735.66 152.917 768.879 150.505 767.236 133.964C765.246 114.236 784.364 114.925 784.364 114.925L789.901 114.753L771.474 154.899H701.403V3.27393L727.01 3.53238Z"
      className="fill-with-text-color"
    />
    <path
      d="M921.824 129.484C909.799 145.853 894.747 155.071 876.061 156.794C857.462 158.345 840.853 152.228 826.406 138.186C811.873 124.143 803.741 106.224 801.665 84.5999C799.848 62.9762 804.779 43.9369 816.804 27.4822C828.828 11.1136 843.88 1.89546 862.566 0.172453C881.165 -1.37826 897.602 4.73843 912.221 18.781C926.582 32.9958 934.713 50.7428 936.617 72.3666C938.606 94.0765 933.675 113.116 921.824 129.484ZM911.529 23.4331C901.235 10.3382 889.643 5.25533 876.494 5.68609C845.87 6.80604 851.061 22.3993 838.69 37.3033C826.319 52.3797 806.077 50.312 806.25 82.2739C806.336 102.088 816.89 118.629 826.319 131.897C835.749 145.422 846.995 148.782 859.711 150.247C890.248 153.865 891.46 132.155 904.609 116.648C917.671 101.313 932.464 109.584 932.291 72.3666C932.118 52.8966 921.824 36.7864 911.529 23.4331Z"
      className="fill-with-text-color"
    />
    <path
      d="M1015.86 122.075L1070.79 3.36008H1073.9L1004.18 158L925.717 3.27393L954.351 3.53238L1015.86 122.075Z"
      className="fill-with-text-color"
    />
    <path
      d="M1099.51 80.9817V152.314C1108.16 152.745 1155.57 151.711 1155.57 151.711L1155.74 154.985H1082.99V3.27393H1155.91V5.85844C1155.91 5.85844 1109.72 6.11689 1099.6 6.11689V77.6218"
      className="fill-with-text-color"
    />
    <path
      d="M1099.68 154.899C1099.68 154.899 1127.8 151.539 1133.59 141.287C1139.39 131.035 1156.09 134.481 1156.09 134.481V154.899H1099.68Z"
      className="fill-with-text-color"
    />
    <path
      d="M1099.68 5.34131C1099.68 5.34131 1127.8 8.70118 1133.59 18.9531C1139.39 29.205 1156.09 25.759 1156.09 25.759V5.34131H1099.68Z"
      className="fill-with-text-color"
    />
    <path
      d="M1099.77 77.4492H1155.83V80.7229H1099.77"
      className="fill-with-text-color"
    />
  </svg>
)

export default LogoSvg
