import React, { useState } from "react"

// Import Global Context(s)
import MenuContext from "~context/menu"

const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <MenuContext.Provider value={[isMenuOpen, setIsMenuOpen]}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
