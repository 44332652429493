import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import classnames from "classnames"
import { navigate } from "gatsby"

/* Import Global Contexts */
import MenuContext from "~context/menu"
import OnboardingContext from "~context/onboarding"

/* Import Global Components */
import LogoSvg from "~components/logo/logo"
import DoubleRightArrowSvg from "~components/icon/normal/doubleRightPointer"
import HamburgerSvg from "~components/icon/normal/hamburger"
import CrossSvg from "~components/icon/normal/cross"

/* Import Local Styles */
import "./header.css"

const Header = ({ location }) => {
  const [isOnboardingOpen, setIsOnboardingOpen] = useContext(OnboardingContext)
  const [, setIsMenuOpen] = useContext(MenuContext)
  const [showLocation, setShowLocation] = useState(false)
  const [locationString, setLocationString] = useState()

  useEffect(() => {
    if (
      location &&
      location.pathname &&
      !location.pathname.includes("welcome") &&
      isOnboardingOpen
    ) {
      setIsOnboardingOpen(false)
    }
  }, [location, isOnboardingOpen, setIsOnboardingOpen])

  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname.includes("works")) {
        setLocationString("work")
      } else if (location.pathname.includes("work")) {
        setLocationString("work")
      } else if (location.pathname.includes("ip")) {
        setLocationString("ip")
      } else if (location.pathname.includes("about")) {
        setLocationString("about")
      } else if (location.pathname.includes("contact")) {
        setLocationString("contact")
      } else if (location.pathname.includes("signup")) {
        setLocationString("pupdates")
      } else {
        setLocationString(undefined)
      }
    }
  }, [location])

  useEffect(() => {
    if (locationString) {
      setShowLocation(true)
    } else {
      setShowLocation(false)
    }
  }, [locationString])

  const openMenu = () => {
    setIsMenuOpen(true)
  }

  return (
    <div className="header">
      <Link
        className={classnames("close-onboarding", { open: isOnboardingOpen })}
        to={"/"}
      >
        <CrossSvg />
      </Link>
      <div className="logo-container">
        <div
          className={classnames("logo-wrapper", {
            "left-align": !isOnboardingOpen,
          })}
        >
          <Link to="/" className="logo">
            <LogoSvg />
          </Link>
        </div>
        <button
          className={classnames("location", { "show-location": showLocation })}
          onClick={() => {
            if (location.pathname.includes("/work/")) {
              navigate("/works/")
            } else if (
              location.pathname.includes("works") ||
              location.pathname.includes("ip")
            ) {
              if (window && window.fullpage_api) {
                window.fullpage_api.moveTo(1, 0)
              }
            } else {
              navigate("/")
            }
          }}
        >
          <p>
            <span>
              <DoubleRightArrowSvg />
            </span>
            <SwitchTransition className="transition-group">
              <CSSTransition
                key={locationString}
                classNames="transition"
                timeout={250}
              >
                <span className="location-string">
                  {locationString === "work" ? "featured work" : locationString}
                </span>
              </CSSTransition>
            </SwitchTransition>
          </p>
        </button>
      </div>
      <button
        className={classnames("hamburger", {
          "is-onboarding": isOnboardingOpen,
        })}
        onClick={openMenu}
      >
        <HamburgerSvg />
      </button>
    </div>
  )
}

export default Header
