import React from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import "./transitions.css"

const TransitionRouter = ({ location, children }) => (
  <SwitchTransition className="transition-group">
    <CSSTransition key={location.key} classNames="transition" timeout={250}>
      {/* the only difference between a router animation and
                any other animation is that you have to pass the
                location to the router so the old screen renders
                the "old location" */}
      <main className="page-wrapper">{children}</main>
    </CSSTransition>
  </SwitchTransition>
)

export default TransitionRouter
