import React, { useState, useEffect } from "react"
import { useMount } from "react-use"
import ls from "local-storage"

// Import Global Context(s)
import VoiceContext from "~context/voice"

const VoiceProvider = ({ children }) => {
  const [voice, setVoice] = useState(ls("puppy-love-voice"))

  useMount(() => {
    // if undefined in local storage, set to default voice ( cowboy )
    if (voice === null || voice === undefined) {
      setVoice("cowboy")
      return
    }
  })

  useEffect(() => {
    if (voice) {
      ls("puppy-love-voice", voice)
    }
  }, [voice])

  return (
    <VoiceContext.Provider value={[voice, setVoice]}>
      {children}
    </VoiceContext.Provider>
  )
}

export default VoiceProvider
