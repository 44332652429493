import React, { useState } from "react"

// Import Global Context(s)
import OnboardingContext from "~context/onboarding"

const OnboardingProvider = ({ children, location }) => {
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  return (
    <OnboardingContext.Provider value={[isOnboardingOpen, setIsOnboardingOpen]}>
      {children}
    </OnboardingContext.Provider>
  )
}

export default OnboardingProvider
