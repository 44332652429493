import React from "react"
import { Helmet } from "react-helmet"

/* Import Providers */
import ColorProvider from "~providers/color"
import MenuProvider from "~providers/menu"
import FooterProvider from "~providers/footer"
import VoiceProvider from "~providers/voice"
import OnboardingProvider from "~providers/onboarding"
import FirstVisitProvider from "~providers/firstVisit"

/* Import Global Components */
import TransitionRouter from "~components/transitionRouter/transitionRouter"
import LoadingOverlay from "~components/loadingOverlay"
import Header from "~components/header"
import Menu from "~components/menu"
import Footer from "~components/footer"

/* Focus Visible Polyfill ( https://github.com/WICG/focus-visible ) */
import "focus-visible/dist/focus-visible.js"

/* Import Fonts */
import "~assets/fonts/GT-Eesti-Text-Light/GT-Eesti-Text-Light.css"
import "~assets/fonts/GT-Eesti-Text-Regular-Capitalized/GT-Eesti-Text-Regular-Capitalized.css"
import "~assets/fonts/GT-Eesti-Text-Medium/GT-Eesti-Text-Medium.css"

/* Import Variables */
import "~styles/variables.css"

/* Import Reset */
import "~styles/reset.css"

/* Import Application Styles */
import "~styles/app.css"

/* Import Layout Styles */
import "./layout.css"

const Layout = ({ location, children }) => (
  <FirstVisitProvider>
    <ColorProvider>
      <MenuProvider>
        <FooterProvider>
          <VoiceProvider>
            <OnboardingProvider location={location}>
              <Helmet title="Puppy Love" />
              <LoadingOverlay />
              <Header location={location} />
              <Menu location={location} />
              <TransitionRouter location={location}>
                {children}
              </TransitionRouter>
              <Footer location={location} />
            </OnboardingProvider>
          </VoiceProvider>
        </FooterProvider>
      </MenuProvider>
    </ColorProvider>
  </FirstVisitProvider>
)

export default Layout
